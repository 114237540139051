import React, { useEffect, useState} from 'react';
import QRCode from 'react-qr-code';
import Player from '@vimeo/player';

const TRAILER_ID = 508404880;

const MobilePrompt = (props) => {

	const [currentURL, setCurrentURL] = useState(null);

	useEffect(() => {
		setCurrentURL(window.location.href);

		let trailer = new Player(`trailer`, {
			color: `c8bfb0`,
			byline: false,
			portrait: false,
			id: TRAILER_ID
		});
	}, []);

	return (
		<div className="home-wrapper">
			<section className="overflow-fix text-center normal padding-x-small">

				<div className="mb-big mt-0 mobile-prompt">

					<h1 className="main-title mt-small">Trust in the Blockchain Society</h1>
					<p>Trust in the Blockchain Society is a smartphone documentary.</p>
					<p>Please scan the QR code to access the full experience on your mobile device.</p>

					{currentURL && <QRCode value={currentURL} bgColor="#000" fgColor="#c8bfb0" size="180" />}

					<div className="video-wrapper mt-small">
						<div id="trailer"></div>
					</div>

				</div>
				
			</section>
		</div>
	);
}

export default MobilePrompt;
