/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import '../styles/index.scss';
import Helmet from "react-helmet";
import CookieConsent from 'react-cookie-consent';
import {isMobile, browserName, getUA} from 'react-device-detect';	
import MobilePrompt from './MobilePrompt';
import axios from 'axios';

const Layout = ({ children, slug, image, titleAddition }) => {
	

	const meta = require(`../data/seo.json`);

	const [showPrompt, setShowPrompt] = useState(false);

	useEffect(() => {
		let mobile = isMobile || browserName.toLowerCase().includes(`android`)

		if(
			getUA.toLowerCase().includes(`mobile`)
			&& getUA.toLowerCase().includes(`duckduckgo`)
		){
			mobile = true;
		}
		setShowPrompt(!mobile);
	}, []);

	let pageUrl = slug ? `${meta.base_url}/${slug}` : meta.base_url;

	let consentStyles = {
		container: {
			background: `rgba(0,0,0,.75)`
		},
		text: {
			fontSize: `.75rem`,
			margin: 0
		},
		content: {
			flex: `1 0 360px`
		},
		buttonWrapper: {
			margin: `0 auto`
		},
		button: {
			fontFamily: `InterRegular, sans-serif`,
			background: `#9a8c89`,
			color: `white`,
			margin: 0,
			marginBottom: `1rem`
		}
	}
	
	return (
		<div>
			<Helmet defer={false}>
				<html lang={meta.lang} />
				<title>{meta.title}</title>
				<meta charset="utf-8" />

				{/* TEMPORARY */}

				<meta name="description" content={meta.description} />
				<meta name="keywords" content={meta.keywords} />

				{ /* SOCIAL */}
				<meta property="og:locale" content={meta.lang} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={meta.title} />
				<meta property="og:description" content={meta.description} />
				<meta property="og:url" content={pageUrl} />
				<meta property="og:site_name" content={meta.title} />
				<meta property="og:image" content={`${meta.base_url}${image}`} />
				<meta name="twitter:image:alt" content={meta.description} />
				<meta name="twitter:card" content="summary_large_image" />

				<meta name="theme-color" content="#000" />

				{ /* Makes the page extend on iOS all the way to the top edge */ }
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
			</Helmet>
			{showPrompt ? 
				<MobilePrompt /> 
				: <main>{children}</main>
			}

			<CookieConsent
				style={consentStyles.container}
				buttonStyle={consentStyles.button}
				contentStyle={consentStyles.content}
				buttonWrapperClasses="consent-button"
				location="bottom"
				buttonText="Accept"
				cookieName="gatsby-gdpr-google-analytics"
			>
				<p style={consentStyles.text}>This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you, improve and customize your browsing experience, and for analytics and metrics about our visitors on this website. </p>
			</CookieConsent>
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
